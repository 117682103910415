import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useDrag, useDrop } from "react-dnd";
import { addListField, moveListField } from "redux/reportSlice";
import { getDisplayNameForField } from "utilities/reportEditor";

function FormatListItem({ field, listIndex, matterTypeIds, maxEntries }) {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const [cellDisplayValue, setCellDisplayValue] = useState("");

  useEffect(() => {
    if (field?.dataFieldName) {
      setCellDisplayValue(getDisplayNameForField(field?.dataFieldName, matterTypeIds));
    } else {
      setCellDisplayValue("");
    }
  }, [field?.dataFieldName]);

  const [, drop] = useDrop(
    () => ({
      accept: ["box", "self"],
      drop(item) {
        switch (item.type) {
          case "box":
            dispatch(addListField({ fieldName: item.name, toIndex: listIndex, maxEntries }));
            break;
          case "self":
            dispatch(moveListField({ fromIndex: item.listIndex, toIndex: listIndex }));
            break;
        }
      },
    }),
    [listIndex, maxEntries]
  );

  const [, drag] = useDrag(
    () => ({
      type: "self",
      item: { type: "self", listIndex },
    }),
    [listIndex]
  );

  drag(drop(ref));

  return (
    <div className="quick-report-grid__cell" ref={ref}>
      {cellDisplayValue}
    </div>
  );
}

FormatListItem.propTypes = {
  field: PropTypes.object,
  listIndex: PropTypes.number,
  matterTypeIds: PropTypes.arrayOf(PropTypes.number),
  maxEntries: PropTypes.number,
};

export default FormatListItem;
