import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReportSectionHeader from "./ReportSectionHeader";
import ReportSectionGroup from "./ReportSectionGroup";
import ReportSectionRow from "./ReportSectionRow";

function ReportSection({
  format,
  section,
  styleItems,
  columnStyleItems,
  sectionGroup,
  firstSection,
  matterTypeIds,
  isTopLevel,
  onDragMouseDown,
  dragState,
}) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (section) {
      const rows = [];
      for (let i = 1; i <= section.rowCount; i++) {
        let row = null;
        if (section.rows) {
          row = section.rows.find((row) => row.row === i);
        }
        rows.push(row ? row : { row: i });
      }
      setRows(rows);
    }
  }, [section]);

  const [mergedCells, setMergedCells] = useState([]);

  useEffect(() => {
    if (section) {
      const mergedCells = new Array(section.rows);

      for (let row = 1; row <= section.rowCount; row++) {
        mergedCells[row - 1] = new Array(format.gridColumnCount);
      }

      if (section.cells) {
        for (let row = 1; row <= section.rowCount; row++) {
          for (let col = 1; col <= format.gridColumnCount; col++) {
            if (section.cells) {
              let cell = section.cells.find((cell) => cell.row === row && cell.column === col);
              if (!cell) {
                continue;
              }
              if ((!cell.rowSpan || cell.rowSpan === 1) && (!cell.colSpan || cell.colSpan === 1)) {
                continue;
              }
              for (let spanRow = cell.row; spanRow <= cell.row + (cell.rowSpan ?? 1) - 1; ++spanRow) {
                for (let spanCol = cell.column; spanCol <= cell.column + (cell.colSpan ?? 1) - 1; ++spanCol) {
                  if (spanRow === cell.row && spanCol === cell.column) {
                    continue;
                  }
                  mergedCells[spanRow - 1][spanCol - 1] = true;
                }
              }
            }
          }
        }
      }
      setMergedCells(mergedCells);
    }
  }, [section, format.gridColumnCount, section.rowCount, section.rows]);

  return (
    <>
      <ReportSectionHeader
        format={format}
        section={section}
        sectionGroup={sectionGroup}
        firstSection={firstSection}
        matterTypeIds={matterTypeIds}
        isTopLevel={isTopLevel}
      />
      {rows.map((row) => (
        <ReportSectionRow
          key={row.row}
          format={format}
          prevRow={rows[row.row - 2]}
          row={row}
          section={section}
          styleItems={styleItems}
          columnStyleItems={columnStyleItems}
          mergedCells={mergedCells}
          onDragMouseDown={onDragMouseDown}
          dragState={dragState}
        />
      ))}
      {section.sectionGroups &&
        section.sectionGroups.map((sectionGroup) => {
          return (
            <ReportSectionGroup
              key={sectionGroup.id}
              format={format}
              sectionGroup={sectionGroup}
              styleItems={styleItems}
              columnStyleItems={columnStyleItems}
            />
          );
        })}
    </>
  );
}

ReportSection.propTypes = {
  format: PropTypes.object,
  section: PropTypes.object,
  styleItems: PropTypes.array,
  columnStyleItems: PropTypes.array,
  sectionGroup: PropTypes.object,
  firstSection: PropTypes.bool,
  matterTypeIds: PropTypes.array,
  isTopLevel: PropTypes.bool,
  onDragMouseDown: PropTypes.func,
  dragState: PropTypes.object,
};

export default ReportSection;
