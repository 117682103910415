import PropTypes from "prop-types";
import { t } from "locale/dictionary";
import React from "react";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { runBatchMatterSearch } from "api/batchCreate";
import { useDispatch, useSelector } from "react-redux";
import { parseSearchResponse } from "utilities/searchResults";
import { setResults } from "redux/searchResultsSlice";
import { useEffect } from "react";
import { setLinkedMatters } from "redux/batchCreateSlice";
import SearchResultsDataGrid from "components/content/search-results/SearchResultsDataGrid";
import SearchResultsFooter from "components/content/search-results/SearchResultsFooter";

function BatchCreateMatterSearch({ isPriority, onCanBeCommittedChange }) {
  const batchCreateState = useSelector((state) => state.batchCreate);
  const searchResultsState = useSelector((state) => state.searchResults).currentSearch;
  const project = batchCreateState?.project;

  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    onCanBeCommittedChange(searchResultsState?.selected?.length > 0);
    const linkedMattersProject = searchResultsState?.selected?.map((sel, index) => ({ id: index, matterId: sel }));
    dispatch(setLinkedMatters(linkedMattersProject));
  }, [searchResultsState.selected]);

  const handleSearchTextChange = (e) => {
    const searchTextLocal = e.target.value;
    if (searchTextLocal.length > 2)
      runBatchMatterSearch(1, project.project_MatterTypeId, searchTextLocal).then((response) => {
        const cols = parseSearchResponse(response);
        dispatch(setResults({ cols, logId: response.logId }));
      });
    setSearchText(searchTextLocal);
  };

  const handleSelectChange = (selected) => {
    dispatch(setLinkedMatters(searchResultsState.selected));
  };

  const renderSearchBar = () => {
    return (
      <div className="search__bar">
        <span>{t(isPriority ? "Link Existing Record(s)" : "Extract from Existing Record")}</span>
        <input
          type="text"
          placeholder={t("Enter matter name, trademark number or country")}
          value={searchText}
          onChange={handleSearchTextChange}
        />
        <div className="search__icon">
          <FaSearch />
        </div>
      </div>
    );
  };

  return (
    searchResultsState && (
      <div className="batch-create__search">
        {renderSearchBar()}
        <div className="search__table">
          <SearchResultsDataGrid
            isBatchCreate={true}
            isPriority={isPriority}
            onSelectChange={handleSelectChange}
            searchText={searchText}
          />
        </div>
        {searchResultsState?.pagination?.totalPages > 1 && <SearchResultsFooter />}
      </div>
    )
  );
}

BatchCreateMatterSearch.propTypes = {
  isPriority: PropTypes.bool,
  onCanBeCommittedChange: PropTypes.func,
};

export default BatchCreateMatterSearch;
