import { useDispatch, useSelector } from "react-redux";
import {
  FaAngleDown,
  FaTimes,
  FaEquals,
  FaNotEqual,
  FaLessThanEqual,
  FaGreaterThanEqual,
  FaRegCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";
import { IoIosCalendar } from "react-icons/io";
import {
  clearFilter,
  fieldTextValueChange,
  labelSelectedChange,
  setSavedSearchLoadedId,
} from "redux/simpleSearchSlice";
import PropTypes from "prop-types";
import { getFilterDescriptionString, processDataFields } from "utilities/simpleSearch";
import SuggestedFieldPopupContainer from "./SuggestedFieldPopupContainer";
//import SearchCriteriaPopup from "components/header/SavedSearchCriteriaPopup";
import useComponentVisible from "components/global/useComponentVisible";
import { searchViewTypes } from "utilities/constants";
import { accessRightFilterDelete } from "api/security";
import { deleteBatchImportSearchFilter } from "api/batchImport";
import Delete from "components/global/Delete";

// Container for the selected filter box
function SelectedFilter({ filter, isExpandedText, readOnly }) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const securityState = useSelector((state) => state.security);
  const batchImportState = useSelector((state) => state.batchImport);
  const appState = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const [refPopup, isPopupVisible, setIsPopupVisible] = useComponentVisible(false);

  const fields = simpleSearch.fields;

  const field = fields.find((field) => field.fieldName === filter.fieldName);

  const onBodyClick = () => {
    dispatch(
      labelSelectedChange({
        section: field.group,
        fieldName: filter.fieldName,
        clickSource: "Filter",
      })
    );
    if (!filter.qualified) {
      dispatch(fieldTextValueChange(filter.valueArray.length >= 1 ? filter.valueArray.join(",") : null));
    }
    setIsPopupVisible(!isPopupVisible);
  };

  const onCloseClick = () => {
    if (simpleSearch.viewType === searchViewTypes.ACCESS_RIGHTS) {
      const accessRight = securityState.currentUserGroup.accessRight;
      accessRightFilterDelete(securityState.currentCustomer.id, accessRight.id, filter.id);
    }
    if (simpleSearch.viewType === searchViewTypes.BATCH_IMPORT) {
      deleteBatchImportSearchFilter(appState.customerId, batchImportState.project.id, filter.id);
    } else {
      dispatch(clearFilter(filter.fieldName));
    }
    dispatch(setSavedSearchLoadedId(null));

    // Special case if matter types is cleared - we need to reset all fields so that all possible lookup values are available again
    // WARNING: Did the simpleSearchState.queryType get reset? If so need to wait until it's reset to then run processDataFields
    if (filter.fieldName === "matter_MatterTypeId") {
      processDataFields();
    }
  };

  let fieldDisplayName = field?.displayName ?? "#NAME";
  if (filter.section) fieldDisplayName = `${fieldDisplayName} (${filter.section})`;

  let filterOperator = null;
  switch (filter.operator) {
    case "=":
      filterOperator = <FaEquals />;
      break;
    case "<>":
      filterOperator = <FaNotEqual />;
      break;
    case "<=":
      filterOperator = <FaLessThanEqual />;
      break;
    case ">=":
      filterOperator = <FaGreaterThanEqual />;
      break;
    case "between":
      filterOperator = <IoIosCalendar />;

      break;
    case "contains":
      filterOperator = "*";
      break;
    case "notcontains":
      filterOperator = (
        <>
          <FaNotEqual />*
        </>
      );
      break;
    case "begins":
      filterOperator = "*__";
      break;
    case "ends":
      filterOperator = "__*";
      break;
    case "True":
      filterOperator = <FaRegCheckCircle />;
      break;
    case "False":
      filterOperator = <FaTimesCircle />;
      break;
    default:
      break;
  }

  let displayValue = getFilterDescriptionString(filter, null, isExpandedText);
  //const popupDisplayValue = getFilterDescriptionString(filter, true, true, true);

  const headerClassNameBase = "simple-search-filter__header";
  const popupContainerClassNameBase = "simple-search-filter__popup-container";
  let headerClassName = headerClassNameBase;
  let popupContainerClassName = popupContainerClassNameBase;
  if (filter.fieldName === "matter_MatterTypeId") {
    headerClassName = `${headerClassNameBase} ${headerClassNameBase}--matter-type`;
    popupContainerClassName = `${popupContainerClassNameBase} ${popupContainerClassNameBase}--matter-type`;
  } else if (simpleSearch.viewType === searchViewTypes.ACCESS_RIGHTS) {
    headerClassName = `${headerClassNameBase} ${headerClassNameBase}--access-rights`;
    popupContainerClassName = `${popupContainerClassNameBase} ${popupContainerClassNameBase}--access-rights`;
  } else if (readOnly) headerClassName = `${headerClassNameBase} ${headerClassNameBase}--read-only`;

  const renderSelectedFilter = (
    <div className="simple-search-filter__container" ref={refPopup}>
      <div className={headerClassName}>
        {fieldDisplayName}&nbsp;{filterOperator}
      </div>
      <div className="simple-search-filter__body">
        {/* <SearchCriteriaPopup criteriaStrings={[popupDisplayValue]} delay={1000}>
          <> */}
        <span onClick={onBodyClick}>
          <FaAngleDown />
          &nbsp;{displayValue}&nbsp;
        </span>
        {filter.fieldName === "matter_MatterTypeId" ? (
          <Delete
            message="Delete the Matter Type Filter? Without this filter, search field names may become more difficult to determine"
            onConfirm={onCloseClick}
            customCssClass={""}
          />
        ) : (
          <div onClick={onCloseClick}>
            <FaTimes className="clickable" />
          </div>
        )}
        {/* </>
        </SearchCriteriaPopup> */}
      </div>
      {isPopupVisible && (
        <div className={popupContainerClassName}>
          <SuggestedFieldPopupContainer fields={[field]} filter={filter} parent="Filter" />
        </div>
      )}
    </div>
  );

  const renderReadOnlyFilter = (
    <div className="simple-search-filter__container">
      <div className={headerClassName}>
        {fieldDisplayName}&nbsp;{filterOperator}
      </div>
      <div className="simple-search-filter__body simple-search-filter__body--read-only">
        <span>&nbsp;{displayValue}&nbsp;</span>
      </div>
    </div>
  );

  //MAIN RENDER
  return <>{!readOnly ? renderSelectedFilter : renderReadOnlyFilter}</>;
}

SelectedFilter.propTypes = {
  filter: PropTypes.object,
  isExpandedText: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default SelectedFilter;
