import { t } from "locale/dictionary";
import {
  addFilter,
  clearAllFilters,
  clearFilter,
  resetReloadFieldsForMatterTypes,
  setAllFilters,
  setFields,
} from "redux/simpleSearchSlice";
import store from "redux/store";
import { stringToBoolean } from "utilities/boolean";
import { getLabelTypeFromString, labelTypes, matterTypes, searchViewTypes } from "utilities/constants";
import { getCountryCodeAlpha2FromIsoCode } from "utilities/countries";
import { getUserFullNameFromId } from "utilities/customer";
import {
  getAllLookupValuesForField,
  getDataTypeForField,
  getDisplayNameForField,
  getLookupValueForField,
} from "utilities/datafield";
import { getAllLookupValuesForSource, getFieldsExcludedByMatterTypes } from "utilities/lookup";
import {
  compareByName,
  compareByFieldWeighting,
  deDuplicateStringsWithSameStartingWord,
  findById,
  getBooleanDisplayValue,
  hasMatchingId,
} from "utilities/stringAndArray";
import { getDateDisplayValue } from "./dateTime";

export function processDataFields(matterTypeIds) {
  const state = store.getState();
  const allDataFields = state.dataField?.dataFields?.length > 0 ? state.dataField.dataFields : [];
  const dataFields =
    state.simpleSearch.currentSearch.viewType === searchViewTypes.ACCESS_RIGHTS
      ? allDataFields.filter((field) => field.isAccessRightFilter)
      : allDataFields;

  const simpleSearch = state.simpleSearch.currentSearch;
  const translatedOverrides = state.dataField?.translatedOverrides;

  const favouriteFieldNames = simpleSearch.favouriteFields.map((favourite) => favourite.dataFieldName);
  const dataSources = state.lookup.global.find((lookup) => lookup.name === "DataSources");

  // Filter fields that are only relevant for the selected matter type(s)
  let fields = dataFields;
  if (matterTypeIds) {
    const excludedFields = getFieldsExcludedByMatterTypes(matterTypeIds);
    fields = dataFields.filter((field) => !excludedFields.some((ef) => ef.dataFieldName === field.name));
  }

  fields = fields.map((field) => {
    const isFavourite = favouriteFieldNames.some((favourite) => favourite === field.name);
    try {
      return processField(state, dataSources, field, matterTypeIds, isFavourite, translatedOverrides);
    } catch (e) {
      // console.log(`Unable to process Field '${field.name}. Exception detail: ${e}`);
      return null;
    }
  });

  fields = fields.filter((field) => field !== null);
  fields = fields.sort(compareByFieldWeighting);

  // Update redux with processed set of fields

  store.dispatch(setFields(fields));

  return fields;
}

function processField(state, dataSources, dataField, matterTypeIds, isFavourite, translatedOverrides) {
  // if (state.simpleSearch.currentSearch.viewType === searchViewTypes.ACCESS_RIGHTS) debugger;
  const queryType = state.simpleSearch?.currentSearch?.queryType?.dataSourceName ?? "Matter";
  if (dataField.noFilter || rootDataSource(dataSources, dataField.dataSourceName) !== queryType) return null;

  const fieldName = dataField.name;
  const displayName = getDisplayNameForField(fieldName, matterTypeIds, null, translatedOverrides);
  const dataType = dataField.lookupDataSourceName ? labelTypes.LOOKUP : getLabelTypeFromString(dataField.dataType);
  const qualifyType = dataField.qualifyType;
  const group = dataField.dataFieldGroupName;
  const sortIndex = dataField.dataFieldGroupSortIndex;

  let returnField = { fieldName, displayName, dataType, isFavourite, qualifyType, group, sortIndex };
  if (dataType === labelTypes.LOOKUP) {
    const lookupDisplayList = getAllLookupValuesForField(fieldName, matterTypeIds, true);
    // For list type lists we need to filter on listTypeId, contained in the field name
    if (dataField.subType === "list") {
      const listIdInFieldName = fieldName.match(/\d/g).join("");
      returnField.lookupDisplayList = lookupDisplayList.filter(
        (lookup) => listIdInFieldName === `${lookup.listTypeId}`
      );
    } else returnField.lookupDisplayList = lookupDisplayList;
  }
  return returnField;
}

function rootDataSource(dataSources, dataSourceName) {
  const dataSource = dataSources.lookup.find((dataSource) => dataSource.name === dataSourceName);

  if (!dataSource) {
    return null;
  } else if (dataSource.parent === null) {
    return dataSource.name;
  } else {
    return rootDataSource(dataSources, dataSource.parent);
  }
}

export function getMatterTypeIds() {
  const state = store.getState();
  const simpleSearch = state.simpleSearch.currentSearch;
  const matterTypeFilter = simpleSearch.filters.find((filter) => filter.fieldName === "matter_MatterTypeId");
  if (!matterTypeFilter) {
    return [];
  }
  const matterTypeIds = matterTypeFilter.valueArray;

  return matterTypeIds;
}

function removeRedundantFilters() {
  const state = store.getState();
  const simpleSearch = state.simpleSearch.currentSearch;
  const fields = simpleSearch.fields;

  simpleSearch.filters.forEach((filter) => {
    if (filter.fieldName === "matter_MatterTypeId") return;

    const field = fields.find((field) => field.fieldName === filter.fieldName);

    if (!field) {
      store.dispatch(clearFilter(filter.fieldName));
    }
  });
}

function removeRedundantLookupValues(newMatterTypeIds) {
  const state = store.getState();
  const simpleSearch = state.simpleSearch.currentSearch;

  if (!newMatterTypeIds) {
    const matterTypeFilter = simpleSearch.filters.find((filter) => filter.fieldName === "matter_MatterTypeId");
    newMatterTypeIds = matterTypeFilter?.valueArray ?? [];
  }

  // Check for any other active filters that have dropdown lists with values checked
  // These dropdown lists will be altered according to the filtering on the new matter type(s)
  // If possible, checked values will be preserved. They will be lost if the checked value is not within the new filtered list
  simpleSearch.filters.forEach((filter) => {
    if (filter.fieldName === "matter_MatterTypeId") return;
    if (filter.qualified) return;

    const field = simpleSearch.fields.find((field) => field.fieldName === filter.fieldName);

    if (field.dataType === labelTypes.LOOKUP) {
      if (!filter.valueArray) return;
      const valueArray = filter.valueArray.map((value) => Number(value));
      let lookupDisplayListForNewMatterTypes = getAllLookupValuesForField(filter.fieldName, newMatterTypeIds);
      let newValueArray = [];
      lookupDisplayListForNewMatterTypes.forEach((lookupItem) => {
        if (valueArray.includes(lookupItem.id)) {
          newValueArray.push(lookupItem.id.toString());
        }
      });

      if (newValueArray.length === 0) {
        store.dispatch(clearFilter(filter.fieldName));
      } else {
        store.dispatch(
          addFilter({
            fieldName: filter.fieldName,
            operator: filter.operator,
            valueArray: newValueArray,
            qualified: filter.qualified,
          })
        );
      }
    }
  });
}

export function reloadFieldsAndFilterOnMatterType(newMatterTypeIds) {
  const state = store.getState();
  const simpleSearch = state.simpleSearch.currentSearch;

  if (!newMatterTypeIds) {
    const matterTypeFilter = simpleSearch.filters.find((filter) => filter.fieldName === "matter_MatterTypeId");
    newMatterTypeIds = matterTypeFilter ? matterTypeFilter.valueArray.map((mt) => Number(mt)) : null;
  }

  processDataFields(newMatterTypeIds);
  removeRedundantFilters();
  removeRedundantLookupValues(newMatterTypeIds);
}

export async function setDefaultSearch(savedSearchesIn) {
  const state = store.getState();
  const simpleSearch = state.simpleSearch.currentSearch;

  const savedSearches = savedSearchesIn ?? simpleSearch.savedSearches;
  const defaultSearch = findById(savedSearches, simpleSearch.defaultSearchId);
  let defaultSearchFilters = [];

  if (defaultSearch) {
    defaultSearchFilters = defaultSearch.filters;
  } else {
    const defaultSearchFilter = {
      fieldName: "matter_MatterTypeId",
      operator: "=",
      valueArray: [matterTypes.TRADEMARK],
      qualified: false,
    };
    defaultSearchFilters.push(defaultSearchFilter);
  }
  store.dispatch(setAllFilters(defaultSearchFilters));
}

export function getInputBoolean(defaultValue = false) {
  const state = store.getState();
  const simpleSearch = state.simpleSearch.currentSearch;
  const inputValue = simpleSearch.inputValues.find((inputValue) => inputValue.isBoolean);

  if (inputValue) {
    return inputValue.booleanValue;
  } else {
    return defaultValue;
  }
}

export function getInputDate(defaultValue = null, inputPos = 1) {
  const state = store.getState();
  const simpleSearch = state.simpleSearch.currentSearch;
  let foundCount = 0;
  let inputValue = null;
  for (let i = 0; i < simpleSearch.inputValues.length; i++) {
    if (!simpleSearch.inputValues[i].isDate) continue;
    if (++foundCount < inputPos) continue;
    inputValue = simpleSearch.inputValues[i];
    break;
  }

  if (inputValue) {
    return new Date(inputValue.dateValue);
  } else {
    return defaultValue;
  }
}

export function getDefaultOperatorForField(field) {
  if (!field) {
    return "=";
  }

  const state = store.getState();
  const simpleSearch = state.simpleSearch.currentSearch;

  if (simpleSearch.queryType?.isExternal) {
    return "=";
  } else {
    return field.dataType === labelTypes.STRING ? "contains" : "=";
  }
}

function addSimpleSearchFilters(filters) {
  const state = store.getState();
  filters.forEach((filter) => {
    const field = state.simpleSearch.currentSearch.fields.find((field) => field.fieldName === filter.fieldName);

    let filterValues = filter.valueArray;
    if (Array.isArray(filterValues) && field.dataType === labelTypes.LOOKUP) {
      filterValues = filterValues.map((v) => (typeof v === "string" ? Number(v) : v));
    }

    const valueArray =
      filterValues && field.lookupDisplayList
        ? field.lookupDisplayList.filter((lookup) => hasMatchingId(filterValues, lookup.id)).map((lookup) => lookup.id)
        : filterValues;

    store.dispatch(
      addFilter({
        fieldName: filter.fieldName,
        operator: filter.operator,
        valueArray,
        qualified: filter.qualified,
      })
    );
  });
}

export function replaceSimpleSearchFilters(filters) {
  store.dispatch(clearAllFilters(true));
  const matterTypeFilter = filters.find((f) => f.fieldName === "matter_MatterTypeId");
  let matterTypeIds = matterTypeFilter ? matterTypeFilter.valueArray : [];
  matterTypeIds = matterTypeIds.map((v) => (typeof v === "string" ? Number(v) : v));
  reloadFieldsAndFilterOnMatterType(matterTypeIds);
  store.dispatch(resetReloadFieldsForMatterTypes());
  addSimpleSearchFilters(filters);
}

export function mergeFilters(filters1, filters2) {
  let searchFilters = [];

  if (filters1) {
    searchFilters = searchFilters.concat(filters1);
  }
  if (filters2) {
    searchFilters = searchFilters.concat(filters2);
  }

  let deDupFilters = [];

  searchFilters.forEach((filter, index) => {
    const processed = searchFilters.find((f, i) => index > i && f.fieldName === filter.fieldName);
    if (processed) {
      return;
    }
    const matches = searchFilters.filter((f, i) => i !== index && f.fieldName === filter.fieldName);

    if (matches.length > 0) {
      const processedFilter = { ...filter };

      matches.forEach((matchFilter) => {
        if (processedFilter.operator === "=" && matchFilter.operator === "isnotempty") {
          // Redundant operator
          return;
        }
        if (processedFilter.operator === "<>" && matchFilter.operator === "isempty") {
          // Redundant operator
          return;
        }

        if (processedFilter.operator === "isnotempty" && matchFilter.operator === "=") {
          processedFilter.operator = matchFilter.operator;
          processedFilter.valueArray = [...matchFilter.valueArray];
        } else if (processedFilter.operator === "isempty" && matchFilter.operator === "<>") {
          processedFilter.operator = matchFilter.operator;
          processedFilter.valueArray = [...matchFilter.valueArray];
        } else if (matchFilter.operator === "between") {
          // Incompatible merge
          return;
        } else if (processedFilter.operator === matchFilter.operator) {
          processedFilter.valueArray = [...processedFilter.valueArray, ...matchFilter.valueArray];
        } else {
          // Incompatible merge
          return;
        }
      });

      deDupFilters.push(processedFilter);
    } else {
      deDupFilters.push(filter);
    }
  });

  return deDupFilters;
}

export function getFilterDescriptionString(filter, returnFieldName, noTruncation, noDeduplication) {
  const fieldDataType = getDataTypeForField(filter.fieldName);
  let displayValue = "";
  if (fieldDataType === labelTypes.BOOLEAN) {
    displayValue = getBooleanDisplayValue(stringToBoolean(filter.valueArray[0]));
  } else if (filter.operator === "isempty") displayValue = t("Is Empty");
  else if (filter.operator === "isnotempty") displayValue = t("Is Not Empty");
  else {
    let displayValueArray = null;
    let charLimit = noTruncation ? 999 : 7;
    if (fieldDataType === labelTypes.LOOKUP && !filter.qualified) {
      displayValueArray = filter.valueArray.map((id) => getLookupValueForField(filter.fieldName, id));
    } else if (filter.qualified) {
      if (filter.qualifiedDictionary) {
        displayValueArray = filter.valueArray.map((id) => {
          const matterId = Number(id);
          const lookup = findById(filter.qualifiedDictionary, matterId);
          return lookup ? lookup.name : id;
        });
      } else {
        displayValueArray = filter.valueArray;
      }
    } else {
      displayValueArray = filter.valueArray;
    }

    displayValueArray = displayValueArray.map((value) => {
      let v = value;
      if (fieldDataType === labelTypes.DATE) {
        v = getDateDisplayValue(value);
        charLimit = noTruncation ? 999 : 30;
      }
      return v?.length > charLimit ? `${v.substring(0, charLimit)}..` : v;
    });

    let numDuplicates = 0;
    let deduplicatedDisplayValueArray = [];
    if (!noDeduplication) {
      const deduplicationObject = deDuplicateStringsWithSameStartingWord(displayValueArray);
      deduplicatedDisplayValueArray = deduplicationObject.uniqueStrings;
      numDuplicates = deduplicationObject.duplicateCount;
    }

    let joinString = fieldDataType === labelTypes.DATE ? " - " : ", ";

    if (fieldDataType === labelTypes.LOOKUP || filter.qualified) {
      displayValueArray = displayValueArray.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));
    }

    displayValue =
      displayValueArray.length < (noTruncation ? 999 : 3)
        ? displayValueArray.join(joinString)
        : `${deduplicatedDisplayValueArray.slice(0, 2).join(joinString)} [+${
            deduplicatedDisplayValueArray.length + (numDuplicates > 0 ? numDuplicates + 1 : 0) - 2
          }]`;
  }
  return returnFieldName
    ? `${getDisplayNameForField(filter.fieldName)} ${filter.operator} ${displayValue}`
    : displayValue;
}

export function processCompanySearchResultSet(response) {
  const responseDeDuped = [...new Map(response.map((item) => [item["matterId"], item])).values()];
  responseDeDuped.sort(compareByName);

  return (
    responseDeDuped?.map((company) => {
      const name = [company.name, company.city].filter((value) => !!value).join(" - ");
      const fullDescription = [name, company.state, getCountryCodeAlpha2FromIsoCode(company.countryId)]
        .filter((value) => !!value)
        .join(", ");
      return {
        id: company.matterId,
        name: company.name,
        fullDescription: fullDescription + ` (${company.linkCount})`,
      };
    }) ?? []
  );
}

export function processCompanyContactSearchResultSet(response) {
  const responseDeDuped = [...new Map(response.map((item) => [item["matterContactId"], item])).values()];
  responseDeDuped.sort(compareByName);

  return (
    responseDeDuped?.map((companyContact) => {
      const name = [companyContact.givenName, companyContact.familyName].filter((value) => !!value).join(" ");
      const fullDescription = [
        name,
        companyContact.companyName,
        getCountryCodeAlpha2FromIsoCode(companyContact.countryId),
      ]
        .filter((value) => !!value)
        .join(", ");
      return {
        id: companyContact.matterContactId,
        name,
        fullDescription: fullDescription + ` (${companyContact.linkCount})`,
      };
    }) ?? []
  );
}

export function processMatterFieldSearchResultSet(response) {
  const responseDeDuped = [...new Map(response.map((item) => [item["matterId"], item])).values()];

  return (
    responseDeDuped?.map((matterField) => ({
      id: matterField.matterId,
      name: matterField.fieldValue,
      fullDescription: `${matterField.fieldValue} (${matterField.matterName})`,
      matterTypeId: matterField.matterTypeId,
    })) ?? []
  );
}

export function processMatterNameSearchResultSet(response) {
  const matterTypes = getAllLookupValuesForSource("MatterTypes");

  const responseDeDuped = [...new Map(response.map((item) => [item["matterId"], item])).values()];

  const consolidatedMatter = responseDeDuped.reduce((accumulator, matter) => {
    const name = matter.name;
    const matterType = matterTypes.find((mt) => mt.id === matter.matterTypeId);
    accumulator[name] = accumulator[name] || {
      name,
      matterTypes: [{ id: matterType.id, name: matterType.displayValue, count: 0 }],
    };
    const acMatterType = accumulator[name].matterTypes.find((matterType) => matterType.id === matter.matterTypeId);
    if (acMatterType === null) {
      accumulator[name].matterTypes = [
        ...accumulator[name].matterTypes,
        { id: matterType.id, name: matterType.displayValue, count: 1 },
      ];
    } else {
      acMatterType.count++;
    }
    return accumulator;
  }, {});
  const consolidatedMatterArray = Object.values(consolidatedMatter);

  const newSuggestions = consolidatedMatterArray.sort(compareByName);

  newSuggestions.sort(compareByName);

  return newSuggestions;
}

export function processMatterReferenceSearchResultSet(response) {
  return (
    response?.map((reference) => ({
      id: reference.matterReferenceId,
      name: reference.referenceNumber,
      fullDescription: `${reference.referenceNumber} (${reference.matterName})`,
    })) ?? []
  );
}

export function processProjectSearchResultSet(response) {
  return (
    response?.map((project) => ({
      id: project.projectId,
      name: project.projectName,
      fullDescription: `${project.projectName} - ${getUserFullNameFromId(project.createdUserId)}`,
    })) ?? []
  );
}
