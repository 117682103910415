import { getDisplayNameForField } from "utilities/datafield";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { t } from "locale/dictionary";
import { matterTypes } from "utilities/constants";

// Container Component for the header to go with table type data panel for a matter record
function PanelTableHeader({ keyProps, onColHeaderClick, sortCol, sortAsc }) {
  const matterState = useSelector((state) => state.matter);

  const record = keyProps.record;

  return (
    <tr className="data-panel__table-row">
      {keyProps.tableName === "companyLinks" && (
        <td className="data-panel__table-header data-panel__table-header--expander">&nbsp;</td>
      )}
      {keyProps?.fieldList?.map((field) => {
        const fieldName = field.fieldName;

        if (
          matterState &&
          !matterState.showExtraFieldsGoods &&
          (fieldName === "matterGoods_FirstUseDate" || fieldName === "matterGoods_FirstUseInCommerceDate")
        )
          return null;

        if (
          matterState &&
          !matterState.showExtraFieldsDocuments &&
          (fieldName === "matterDocument_EmailDate" ||
            fieldName === "matterDocument_EmailSubject" ||
            fieldName === "matterDocument_EmailFrom" ||
            fieldName === "matterDocument_EmailTo" ||
            fieldName === "matterDocument_EmailImportance")
        )
          return null;

        if (
          record.matter_MatterTypeId !== matterTypes.ASSIGNMENTANDRECORDALS &&
          fieldName === "matterCompanyLink_AssignedDate"
        )
          return null;

        if (field.width === 0) return null;
        let displayName = getDisplayNameForField(fieldName, [record.matter_MatterTypeId], true); // Currently hard-coded to display abbreviated names if available
        if (keyProps.tableName === "companyLinks" && fieldName === "matterCompanyLink_Address1")
          displayName = t("Address");
        if ((keyProps.tableName === "actions" || keyProps.tableName === "comments") && fieldName === "contactLinks")
          displayName = t("Legal Team");

        const className = `data-panel__table-header data-panel__table-header--${field.width}`;
        return (
          <td key={fieldName} className={className} onClick={() => onColHeaderClick(fieldName)}>
            {displayName}
            {sortCol === fieldName ? sortAsc ? <FaCaretUp /> : <FaCaretDown /> : null}
          </td>
        );
      })}
      {keyProps.childTable && (
        <td className="data-panel__table-header data-panel__table-header--action">
          {keyProps.tableName === "companyLinks" ? t("Contacts") : " "}
        </td>
      )}
      {keyProps.tableName !== "contactLinks" && (
        <td className="data-panel__table-header data-panel__table-header--action">&nbsp;</td>
      )}
    </tr>
  );
}

PanelTableHeader.propTypes = {
  keyProps: PropTypes.object,
  sortCol: PropTypes.string,
  sortAsc: PropTypes.bool,
  onColHeaderClick: PropTypes.func,
};

export default PanelTableHeader;
