import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Checkbox from "components/global/Checkbox";
import PropTypes from "prop-types";
import { searchMatterName } from "api/search";
import { getMatterTypeIds, processMatterNameSearchResultSet } from "utilities/simpleSearch";

function SuggestedFieldPopupMatterRecords({ field, valueArray, qualified, onValueChange, onIsApplicableChange }) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const appState = useSelector((state) => state.app);

  const [suggestedResultsObjectList, setSuggestedResultsObjectList] = useState([]); // For qualified matter names
  const [checkedOptions, setCheckedOptions] = useState(qualified && valueArray ? valueArray : []);

  useEffect(() => {
    async function fetchData() {
      const matterTypeIds = field.qualifyType === "MATTER_LINK" ? null : getMatterTypeIds();
      const response = await searchMatterName(appState.customerId, matterTypeIds, simpleSearch.fieldText);
      const newSuggestions = processMatterNameSearchResultSet(response);
      setSuggestedResultsObjectList(newSuggestions);
    }
    fetchData();
  }, [appState.customerId, field.qualifyType, simpleSearch.fieldText]);

  const handleSuggestedMatterClick = (name) => {
    let checkedOptionsTemp = [...checkedOptions];
    let index = checkedOptionsTemp.findIndex((co) => co === name);
    if (index >= 0) {
      checkedOptionsTemp.splice(index, 1);
    } else {
      checkedOptionsTemp = [...checkedOptionsTemp, name];
    }
    checkedOptionsTemp.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));
    setCheckedOptions(checkedOptionsTemp);
    onIsApplicableChange(checkedOptionsTemp.length > 0);

    if (checkedOptionsTemp.length > 0) {
      onValueChange(checkedOptionsTemp, true);
    }
  };

  return (
    <div className="suggested-field-popup__companies">
      {suggestedResultsObjectList &&
        suggestedResultsObjectList.map((item) => {
          const displayValue = `${item.name} (${item.matterTypes.map(
            (matterType) => `${matterType.name} - ${matterType.count}`
          )})`;
          const isChecked = checkedOptions.some((co) => co === item.name);

          return (
            <div className="suggested-field-popup__option" key={item.name}>
              <Checkbox isChecked={isChecked} onCheckChange={(e) => handleSuggestedMatterClick(item.name)} />
              {item && (
                <span className="suggested-field-popup__option suggested-field-popup__option--no-match">
                  {displayValue}
                </span>
              )}
            </div>
          );
        })}
      {checkedOptions
        .filter((matterName) => !suggestedResultsObjectList.some((item) => item.name === matterName))
        .map((matterName) => {
          return (
            <div className="suggested-field-popup__option" key={matterName}>
              <Checkbox isChecked={true} onCheckChange={(e) => handleSuggestedMatterClick(matterName)} />
              {
                <span className="suggested-field-popup__option suggested-field-popup__option--no-match">
                  {matterName}
                </span>
              }
            </div>
          );
        })}
    </div>
  );
}

SuggestedFieldPopupMatterRecords.propTypes = {
  field: PropTypes.object,
  valueArray: PropTypes.array,
  qualified: PropTypes.bool,
  onValueChange: PropTypes.func,
  onIsApplicableChange: PropTypes.func,
};

export default SuggestedFieldPopupMatterRecords;
