import { getDefaultStyleItem, pushStyle } from "utilities/reportEditor";
import PropTypes from "prop-types";
import ReportSectionRowHeader from "./ReportSectionRowHeader";
import ReportSectionCell from "./ReportSectionCell";
import { idsAreEqual } from "utilities/stringAndArray";

function ReportSectionRow({
  format,
  section,
  row,
  prevRow,
  mergedCells,
  styleItems,
  columnStyleItems,
  matterTypeIds,
  isTopLevel,
  onDragMouseDown,
  dragState,
}) {
  //debugger;

  const cells = [];
  for (let col = 1; col <= format.gridColumnCount; col++) {
    if (mergedCells[row.row - 1][col - 1]) {
      continue;
    }
    let cell = null;
    if (section.cells) {
      cell = section.cells.find((cell) => cell.row === row.row && cell.column === col);
    }
    if (cell) {
      cells.push(cell);
    } else {
      cells.push({ row: row.row, column: col });
    }
  }

  const thisStyleItems = [...styleItems];

  //console.log("ReportSectionRow, section: " + JSON.stringify(section) + " row: " + JSON.stringify(row));

  let rowStyle = {};
  let rowHeight = row.rowHeight;
  if (dragState && dragState.row === row.row && idsAreEqual(dragState.sectionId, section.id)) {
    rowHeight = dragState.newHeight;
  }
  if (rowHeight) {
    rowStyle.height = rowHeight + "px";
    rowStyle.maxHeight = rowHeight + "px";
  }

  let sectionStyleItem = getDefaultStyleItem(section.style);
  if (sectionStyleItem) {
    pushStyle(thisStyleItems, "section", sectionStyleItem);
  }

  let styleItem = getDefaultStyleItem(row.style);
  if (styleItem) {
    pushStyle(thisStyleItems, "row", styleItem);
  }

  const renderCells = cells.map((cell) => {
    const rowSpan = cell.rowSpan ? cell.rowSpan : 1;
    const colSpan = cell.colSpan ? cell.colSpan : 1;

    const cellPosition = {
      firstColumn: cell.column === 1,
      lastColumn: cell.column + colSpan - 1 === format.gridColumnCount,
      gridFirstRow: false,
      gridLastRow: false,
      sectionFirstRow: cell.row === 1,
      sectionLastRow: cell.row + rowSpan - 1 === section.rows,
    };

    return (
      <ReportSectionCell
        key={"SC-" + section.id + "-" + cell.row + "-" + cell.column}
        format={format}
        cell={cell}
        row={row}
        cellPosition={cellPosition}
        section={section}
        styleItems={thisStyleItems}
        columnStyleItems={columnStyleItems}
        matterTypeIds={matterTypeIds}
      />
    );
  });

  return (
    <tr key={"SR-" + section.id + "-" + row.row} style={rowStyle}>
      <ReportSectionRowHeader
        section={section}
        row={row}
        prevRow={prevRow}
        isTopLevel={isTopLevel}
        onDragMouseDown={onDragMouseDown}
        dragState={dragState}
      />
      {renderCells}
    </tr>
  );
}

ReportSectionRow.propTypes = {
  format: PropTypes.object,
  section: PropTypes.object,
  mergedCells: PropTypes.array,
  row: PropTypes.object,
  prevRow: PropTypes.object,
  styleItems: PropTypes.array,
  columnStyleItems: PropTypes.array,
  sectionGroup: PropTypes.object,
  firstSection: PropTypes.bool,
  matterTypeIds: PropTypes.array,
  isTopLevel: PropTypes.bool,
  onDragMouseDown: PropTypes.func,
  dragState: PropTypes.object,
};

export default ReportSectionRow;
