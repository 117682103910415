// Constants used throughout the UI

export const matterTypes = {
  COPYRIGHTS: 10,
  DESIGNS: 20,
  DOMAINNAME: 30,
  PATENT: 40,
  SOCIALMEDIA: 50,
  TRADEMARK: 60,
  CUSTOMSRECORDALS: 110,
  GENERALMATTER: 120,
  OTHERPARTYREFERENCE: 130,
  ASSIGNMENTANDRECORDALS: 210,
  BRANDPROTECTION: 220,
  CONTRACT: 230,
  CLEARANCESEARCHES: 240,
  DISPUTE: 250,
  COMPANY: 310,
};

export const exportedSectionDefinitions = {
  GOODS_FOR_BATCH_CREATE: 25,
  LEGAL_TEAM: 60,
};

export const sectionTypes = { FORM: 0, TABLE: 1, THUMBNAILS: 2 };

export const labelTypes = { STRING: 0, LOOKUP: 1, DATE: 2, BOOLEAN: 3, DECIMAL: 4, UNKNOWN: 999 };

export function getLabelTypeFromString(stringLabelType) {
  if (stringLabelType.includes("decimal")) return labelTypes.DECIMAL;
  else {
    switch (stringLabelType) {
      case "string":
        return labelTypes.STRING;
      case "int":
        return labelTypes.DECIMAL;
      case "date":
      case "datetime":
        return labelTypes.DATE;
      case "boolean":
        return labelTypes.BOOLEAN;
      default:
    }
  }

  return `Invalid label data type supplied: ${stringLabelType}`;
}

export const severities = { INFO: 0, WARNING: 1, ERROR: 2, FATAL: 3 };

export const requiredDataTypes = { FIELD: 0, ARRAY: 1, ARRAY_VALUE: 2 };

export const validationStates = { OK: 0, EMPTY_OR_NULL: 1, ERROR: 2, EDITING: 3, SUCCESS: 4, UNKNOWN: 9 };

export const referenceTypes = { ARCHIVE: 100, CASE: 200, CONVERSION: 300, FILE: 400 };

export const linkTypes = { AGENT: 100, CLIENT: 600, OTHER_PARTY: 1600, OWNER: 1900, SERVICE_PROVIDER: 3000 };

export const legalTypes = {
  ATTORNEY: 100,
  MANAGING_PARTNER: 200,
  PARALEGAL: 300,
  SUPERVISOR: 400,
};

export const headerCellTypes = {
  SINGLE_FIELD: 0,
  IMAGE: 1,
  COUNTRIES: 2,
  STATUS_AND_SUBSTATUS: 3,
  TYPE_AND_SUBTYPE: 4,
  LINK: 5,
  NEXT_ACTION: 6,
  REFERENCE: 7,
  TABLE_FIELD_LIST: 8,
  LEGAL_TEAM: 9,
};

export const dndTypes = {
  THUMBNAIL: "thumbnail",
};

export const superSelectOperationTypes = {
  ADD: 0,
  REMOVE: 1,
};

export const widgetTypes = { FAVOURITE_SEARCHES: 1, MRU: 2, BOOKMARKS: 3, DIARY: 4, MAP: 5, CHART: 6 };

export const searchViewTypes = { MAIN: 0, CONNECTED_MATTERS: 1, ACCESS_RIGHTS: 2, BATCH_IMPORT: 3 };

export const userStatusTypes = {
  ACTIVE: 1,
  SUSPENDED: 2,
  ARCHIVED: 3,
  PENDING: 4,
};

export const simpleSearchKeyboardFocusedPopup = { NONE: 0, SEARCH_PANEL: 1, MAIN: 2, RHS: 3 };

export const accessRightTypes = {
  NO_ACCESS: 1,
  READ_ONLY: 2,
  EDIT_ONLY: 3,
  EDIT_AND_CREATE: 4,
  ALL: 5,
};

export const accessRightCategories = {
  REGION: 700,
  DIVISION: 200,
  UNIT: 900,
};

export const projectStatuses = {
  PRE_CREATE: 999,
  CREATED: 0, // "INITIAL" in the back-end
  TEMPLATE_GENERATED: 10, // "PREPARED" in the back-end, records imported, ready to be mapped
  PREVIEW_GENERATED: 20, // "PREVIEWED" in the back-end
  MATTER_GENERATED: 30, // "GENERATED" in the back-end
};

export const projectTypes = {
  BATCH_CREATE: 0,
  BATCH_IMPORT: 10,
};

// NOTE: These may change in the future
// export const queryTypes = {
//   MATTER: 1,
//   MATTERAUDIT: 2,
//   DIARY: 3,
//   COMMENTS: 4,
//   DOCUMENTS: 5,
//   CONTACTS: 6,
//   USPTO: 7,
//   CORSEARCH: 8,
//   BATCHIMPORT: 10,
// };
export const FormatTypes = {
  GRID: 0,
  LIST: 1,
  PIVOT: 2,
  MAP: 3,
};
