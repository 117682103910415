import PropTypes from "prop-types";
import { superSelectOperationTypes } from "utilities/constants";
import { getLookupValueBySource } from "utilities/lookup";

// A generic component for display a selectable list of options
function List({
  id,
  options,
  selectedOptionId,
  selectedOptionIds,
  onChange,
  onChangeMulti,
  isMultiValued,
  cssClassNameModifier,
  showAvailableMatterTypes,
}) {
  const elementId = `list__${id}`;

  const classNameBase = "list";
  const className = cssClassNameModifier ? `${classNameBase} ${classNameBase}--${cssClassNameModifier}` : classNameBase;

  // const [optionsSorted, setOptionsSorted] = useState(null);

  // useEffect(() => {
  //   if (options && options.length > 0) {
  //     setOptionsSorted(options.sort((a, b) => a.displayValue?.localeCompare(b.displayValue)));
  //   }
  // }, [options]);

  // Sort options directly before rendering
  const optionsSorted = options ? options.sort((a, b) => a.displayValue?.localeCompare(b.displayValue)) : [];

  return (
    <div id={elementId} className={className}>
      {optionsSorted &&
        Array.isArray(optionsSorted) &&
        optionsSorted.map((option) => {
          let classNameBase = "list__option";
          const className =
            selectedOptionId && option.id === selectedOptionId
              ? `${classNameBase} ${classNameBase}--selected`
              : classNameBase;

          return (
            <div
              key={option.id}
              className={className}
              onClick={() =>
                isMultiValued ? onChangeMulti(option.id, superSelectOperationTypes.REMOVE) : onChange(option.id)
              }
            >
              {option.displayValue}
              {showAvailableMatterTypes && option.matterTypeIds && (
                <div className="text-x-small-italic">
                  {option.matterTypeIds
                    .map((matterTypeId) => getLookupValueBySource("MatterTypes", matterTypeId))
                    .join(", ")}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}

List.propTypes = {
  id: PropTypes.string,
  options: PropTypes.any, // TODO: fix so it's only array
  selectedOptionId: PropTypes.any,
  selectedOptionIds: PropTypes.array,
  onChange: PropTypes.func,
  onChangeMulti: PropTypes.func,
  isMultiValued: PropTypes.bool,
  cssClassNameModifier: PropTypes.string,
  showAvailableMatterTypes: PropTypes.bool,
};

export default List;
