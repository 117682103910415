import {
  addUserToAccount,
  changeUsersUserGroup,
  createUserGroup,
  deleteUserFromAccount,
  deleteUserGroup,
} from "api/security";
import Delete from "components/global/Delete";
import SuperSelect from "components/global/SuperSelect";
import Tooltip from "components/global/Tooltip";
import { t } from "locale/dictionary";
import { useState } from "react";
import { FaCaretDown, FaCaretUp, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setAccountUsers, setUserGroups } from "redux/securitySlice";
import {
  compareByObjectFieldName,
  compareByReferenceArrayFieldName,
  findById,
  idsAreEqual,
} from "utilities/stringAndArray";

export default function AccountDetails() {
  const securityState = useSelector((state) => state.security);
  const params = useParams();
  const dispatch = useDispatch();

  const [groupNameInput, setGroupNameInput] = useState("");
  const [isAddingNewUser, setIsAddingNewUser] = useState(false);
  const [isAddingNewUserGroup, setIsAddingNewUserGroup] = useState(false);
  const [usersSortOrder, setUsersSortOrder] = useState({});
  const [userGroupsSortOrder, setUserGroupsSortOrder] = useState({});

  const customerId = parseInt(params.customerId);
  const accountId = parseInt(params.accountId);
  const currentAccount = securityState.currentAccount;

  const userOptions = securityState?.currentCustomer?.users
    .filter((user) => !currentAccount?.users.map((user) => user.customerUserId).includes(user.id))
    .map((user) => ({ id: user.id, displayValue: user.fullName }));

  const userGroupOptions = currentAccount?.userGroups?.map((group) => ({
    id: group.id,
    displayValue: group.name,
  }));

  const handleAddNewUserGroup = () => {
    createUserGroup(customerId, accountId, groupNameInput);
    setIsAddingNewUserGroup(false);
  };

  const handleAddUserToAccount = (userId) => {
    addUserToAccount(customerId, accountId, userId);
    setIsAddingNewUser(false);
  };

  const handleSortAccountUsers = (fieldName) => {
    const isSortOnUserGroup = fieldName === "userGroupId";
    let sortedAccountUsers = [];
    const referenceArray = [...currentAccount.userGroups];
    const referenceArrayIdentifier = "id";
    const isAscending = usersSortOrder[fieldName]?.isAscending;
    if (isSortOnUserGroup)
      sortedAccountUsers = compareByReferenceArrayFieldName(
        "name",
        [...currentAccount?.users],
        referenceArray,
        "userGroupId",
        referenceArrayIdentifier,
        isAscending
      );
    else
      sortedAccountUsers = compareByReferenceArrayFieldName(
        fieldName,
        [...currentAccount?.users],
        securityState.currentCustomer.users,
        "customerUserId",
        referenceArrayIdentifier,
        isAscending
      );
    dispatch(setAccountUsers(sortedAccountUsers));
    setUsersSortOrder({ [fieldName]: { isAscending: !usersSortOrder[fieldName]?.isAscending } });
  };

  const handleSortUserGroups = (fieldName) => {
    const sortedUserGroups = compareByObjectFieldName(
      [...currentAccount?.userGroups],
      fieldName,
      userGroupsSortOrder[fieldName]?.isAscending
    );
    dispatch(setUserGroups(sortedUserGroups));
    setUserGroupsSortOrder({ [fieldName]: { isAscending: !userGroupsSortOrder[fieldName] } });
  };

  const renderSortOrderIcon = (table, fieldName) => {
    const isAscending =
      table === "users" ? usersSortOrder[fieldName]?.isAscending : userGroupsSortOrder[fieldName]?.isAscending;
    if (isAscending === undefined) return null;
    return isAscending ? <FaCaretUp /> : <FaCaretDown /> ?? null;
  };

  const renderUserGroupTableBody =
    currentAccount?.userGroups?.length > 0 ? (
      currentAccount.userGroups.map((userGroup) => (
        <tr className="security__table__body-row" key={userGroup.id}>
          <td className="security__table__body-row-cell">
            <Link to={`/customers/${customerId}/accounts/${accountId}/usergroups/${userGroup.id}`}>
              {userGroup.name}
            </Link>
          </td>
          <td className="security__table__body-row-cell">{userGroup.userCount ?? 0}</td>
          <td className="security__table__body-row-cell ">
            {currentAccount.users.filter((user) => idsAreEqual(user.userGroupId, userGroup.id)).length === 0 ? (
              <Delete
                message={"Delete this User Group"}
                onConfirm={() => deleteUserGroup(customerId, currentAccount.id, userGroup.id)}
              ></Delete>
            ) : (
              <Tooltip content="Cannot delete this user group as it contains users" direction="right">
                <FaTimes className="security__table__body-row-cell--disabled" />
              </Tooltip>
            )}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td className="security__table__body__no-data">{t("No User Groups")}</td>
      </tr>
    );

  const renderUserTableBody =
    currentAccount?.users?.length > 0 ? (
      currentAccount.users.map((accountUser) => {
        const userData = {
          ...findById(securityState.currentCustomer.users, accountUser.customerUserId),
          groupId: accountUser.userGroupId,
          groupUserId: accountUser.id,
        };

        return (
          <tr className="security__table__body-row" key={userData.id}>
            <td className="security__table__body-row-cell">
              <Link to={`/customers/${customerId}/accounts/${accountId}/users/${userData.id}`}>
                {userData.fullName}
              </Link>
            </td>
            <td className="security__table__body-row-cell">{userData.email}</td>

            <td className="security__table__body-row-cell ">
              <SuperSelect
                id={userData.userId}
                selectedOptionId={userData.groupId}
                options={userGroupOptions}
                onChange={(optionId) =>
                  changeUsersUserGroup(
                    customerId,
                    accountId,
                    userData.groupUserId,
                    "userGroupId",
                    userData.groupId,
                    optionId
                  )
                }
              />
            </td>
            <td className="security__table__body-row-cell  clickable">
              <Delete
                message={"remove this user from this Account"}
                onConfirm={() => deleteUserFromAccount(customerId, accountId, accountUser.id)}
              ></Delete>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td className="security__table__body__no-data">{t("No Users")}</td>
      </tr>
    );

  const renderUserGroupTable = (
    <>
      <div className="security__section-title">{t("User Groups")}</div>
      <div className="security__table-container">
        <table className="security__table">
          <thead className="security__table__header">
            <tr className="security__table__header-row">
              <th
                onClick={() => handleSortUserGroups("name")}
                className="security__table__header-row-cell security__table__header-row-cell--clickable"
              >
                {t("Name")}
                {renderSortOrderIcon("userGroups", "name")}
              </th>
              <th
                onClick={() => handleSortUserGroups("userCount")}
                className="security__table__header-row-cell security__table__header-row-cell--clickable"
              >
                {t("Users")}
                {renderSortOrderIcon("userGroups", "userCount")}
              </th>
              <th className="security__table__header-row-cell">{t("Delete")}</th>
            </tr>
          </thead>
          <tbody className="security__table__body">
            {renderUserGroupTableBody}
            {isAddingNewUserGroup && (
              <tr className="security__table__body-row">
                <td className="security__table__body-row-cell ">
                  <input
                    className="modal__input"
                    id="addGroupNameInput"
                    type="text"
                    onChange={(e) => setGroupNameInput(e.target.value)}
                  ></input>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!isAddingNewUserGroup ? (
        <button className="flex-row-center" onClick={() => setIsAddingNewUserGroup(true)}>
          {t("Add User Group")}
        </button>
      ) : (
        <button onClick={handleAddNewUserGroup}>{t("Confirm")}</button>
      )}
    </>
  );

  const renderUserTable = (
    <>
      <div className="security__section-title">{t("Users")}</div>
      <div className="security__table-container">
        <table className="security__table">
          <thead className="security__table__header">
            <tr className="security__table__header-row">
              <th
                onClick={() => handleSortAccountUsers("fullName")}
                className="security__table__header-row-cell security__table__header-row-cell--clickable"
              >
                {t("Name")}
                {renderSortOrderIcon("users", "fullName")}
              </th>
              <th
                onClick={() => handleSortAccountUsers("email")}
                className="security__table__header-row-cell security__table__header-row-cell--clickable"
              >
                {t("Email")}
                {renderSortOrderIcon("users", "email")}
              </th>
              <th
                onClick={() => handleSortAccountUsers("userGroupId")}
                className="security__table__header-row-cell security__table__header-row-cell--clickable"
              >
                {t("User Group")}
                {renderSortOrderIcon("users", "userGroupId")}
              </th>
              <th className="security__table__header-row-cell">{t("Delete")}</th>
            </tr>
          </thead>
          <tbody className="security__table__body">
            {renderUserTableBody}
            {isAddingNewUser && (
              <tr className="security__table__body-row">
                <td className="security__table__body-row-cell ">
                  <SuperSelect
                    id="userName"
                    selectedOptionId={null}
                    options={userOptions ?? []}
                    placeholderText="Search for User"
                    onChange={(userId) => handleAddUserToAccount(userId)}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!isAddingNewUser && (
        <button className="flex-row-center" onClick={() => setIsAddingNewUser(true)}>
          {t("Add User")}
        </button>
      )}
    </>
  );

  //MAIN RENDER
  return (
    <>
      {renderUserGroupTable}
      {renderUserTable}
    </>
  );
}
