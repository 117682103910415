import { createSlice } from "@reduxjs/toolkit";

// Redux global state component for storing all lookups from the database
export const lookupSlice = createSlice({
  name: "lookup",
  initialState: {
    global: [],
    customerSpecific: null,
  },
  reducers: {
    setLookup: (state, action) => {
      if (state.global.find((lookup) => lookup.name === action.payload.name)) return;
      let lookups = [...state.global];
      lookups.push({ name: action.payload.name, lookup: action.payload.lookup });
      state.global = lookups;
    },
    addLookupEntry: (state, action) => {
      let table = state.global.find((lookup) => lookup.name === action.payload.name);
      if (!table) return;
      table.lookup.push(action.payload.lookup);
    },
    updateLookupEntry: (state, action) => {
      let table = state.global.find((lookup) => lookup.name === action.payload.name);
      if (!table) return;
      let entry = table.lookup.find((entry) => entry.id === action.payload.lookupId);
      if (!entry) return;
      entry[action.payload.fieldName] = action.payload.newValue;
    },
    removeLookupEntry: (state, action) => {
      let table = state.global.find((lookup) => lookup.name === action.payload.name);
      if (!table) return;
      table.lookup = table.lookup.filter((entry) => entry.id !== action.payload.lookupId);
    },
  },
});

export const { setLookup, addLookupEntry, removeLookupEntry, updateLookupEntry } = lookupSlice.actions;

export default lookupSlice.reducer;
